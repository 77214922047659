<template>
  <div class="manage-branch-add animatedBox">
    <div class="container fluid">
      <fd-form @submit.prevent="submitForm" @error="validateFail">
        <div class="card p-2 mb-2">
          <h4 class="mainTitle pt-3 pb-4">Add New Branch</h4>
          <!-- Branch Details -->
          <fd-form-section title="Branch Details" class="px-2">
            <div class="col-12 row mb-2">
              <fd-select
                v-model="newBranch.agencyId"
                class="col-12 sm-col-6 px-1 mb-2"
                label="Select Agency"
                selectText="Belongs To Agency"
                :options="agencyOptions"
                :validators="[validator.required]"
              >
              </fd-select>
              <fd-input
                v-model="newBranch.name"
                class="col-12 sm-col-6 md-col-3 px-1 mb-2"
                label="Branch Name"
                name="name"
                type="text"
                :validators="[
                  validator.required,
                  (val) => validator.minLength(val, 3)
                ]"
              >
              </fd-input>
              <fd-input
                v-model="newBranch.branchCode"
                class="col-12 sm-col-6 md-col-3 px-1 mb-2"
                label="Branch Code"
                name="branchCode"
                type="text"
                :validators="[validator.required]"
              >
              </fd-input>
            </div>

            <fd-input
              v-model="newBranch.registerEstateAgentName"
              class="col-12 sm-col-6 md-col-3 px-1 mb-2"
              label="Register Estate Agent Name"
              name="registerEstateAgentName"
              type="text"
              :validators="[validator.required]"
            >
            </fd-input>
            <fd-input
              v-model="newBranch.businessRegisterNumber"
              class="col-12 sm-col-6 md-col-3 px-1 mb-2"
              label="Board Reg. No."
              name="businessRegisterNumber"
              type="text"
              :validators="[validator.required]"
            >
            </fd-input>
            <fd-input
              v-model="newBranch.ssmNumber"
              class="col-12 sm-col-6 md-col-3 px-1 mb-2"
              label="SSM"
              name="ssmNumber"
              type="text"
              :validators="[validator.required]"
            >
            </fd-input>
            <fd-input
              v-model="newBranch.sstNumber"
              class="col-12 sm-col-6 md-col-3 px-1 mb-2"
              label="SST No."
              name="sstNumber"
              type="text"
              :validators="[validator.required]"
            >
            </fd-input>
            <fd-input
              v-model="newBranch.ejenHartanahNumber"
              class="col-12 sm-col-6 md-col-3 px-1 mb-2"
              label="Ejen Hartanah No."
              name="ejenHartanahNumber"
              type="text"
              :validators="[validator.required]"
            >
            </fd-input>

            <fd-textarea
              v-model="newBranch.description"
              class="col-12 px-1 mb-2"
              label="Description"
              name="description"
              type="text"
            >
            </fd-textarea>
          </fd-form-section>
          <!-- Contact Details -->
          <fd-form-section title="Contact Details" class="px-2">
            <fd-input
              v-model="newBranch.email"
              class="col-12 sm-col-6 md-col-3 px-1 mb-2"
              label="Email"
              name="email"
              type="text"
              :validators="[validator.required, validator.email]"
            >
            </fd-input>
            <fd-input
              v-model="newBranch.contact"
              class="col-12 sm-col-6 md-col-3 px-1 mb-2"
              label="Contact"
              name="contact"
              type="text"
              :validators="[validator.required, validator.phone]"
            >
            </fd-input>
            <fd-input
              v-model="newBranch.fax"
              class="col-12 sm-col-6 md-col-3 px-1 mb-2"
              label="Fax"
              name="fax"
              type="text"
              :validators="[validator.fax]"
            >
            </fd-input>
            <fd-input
              v-model="newBranch.tenancyAgreementPersonInChargeEmail"
              class="col-12 sm-col-6 md-col-3 px-1 mb-2"
              label="Tenancy Agreement Person In Charge Email"
              name="tenancyAgreementPersonInChargeEmail"
              type="email"
              :validators="[validator.required, validator.email]"
            >
            </fd-input>
          </fd-form-section>
          <!-- Address Details -->
          <fd-form-section title="Address Details" class="px-2">
            <fd-input
              v-model="newBranch.address"
              class="col-12 sm-col-6 md-col-3 px-1 mb-2"
              label="Address"
              name="address"
              type="text"
              :validators="[validator.required]"
            >
            </fd-input>
            <addresses
              :country.sync="newBranch.country"
              :state.sync="newBranch.state"
              :city.sync="newBranch.city"
              :area.sync="newBranch.area"
              class="row col-12"
              @change="
                (data) => {
                  newBranch.countryId = data.country.id;
                  newBranch.stateId = data.state.id;
                  newBranch.cityId = data.city.id;
                  newBranch.areaId = data.area.id;
                }
              "
            ></addresses>
            <!-- Latitude & Longitude (Google Map Geolocator) -->
            <div class="my-2 w-100">
              <map-component @mapUpdate="updateMap"></map-component>
            </div>
          </fd-form-section>
          <div class="text-right p-2">
            <button class="btn main">Create</button>
          </div>
        </div>
      </fd-form>
    </div>
  </div>
</template>

<script>
import {
  required,
  email,
  phone,
  website,
  fax,
  minLength
} from "@/components/GlobalComponents/FormComponents/Validator/rules";

import Addresses from "@/components/GlobalComponents/Address";
import { BranchModel } from "@/models";

export default {
  name: "manage-branch-add",
  components: {
    Addresses,
    "map-component": () =>
      import("@/components/GlobalComponents/GoogleMap/GoogleMap")
  },
  data() {
    return {
      agencyOptions: [],
      // Address
      countryOptions: [],
      stateOptions: [],
      cityOptions: [],
      areaOptions: [],

      // New Branch
      newBranch: {
        agencyId: "",

        country: "",
        state: "",
        city: "",
        area: "",

        countryId: "",
        stateId: "",
        cityId: "",
        areaId: "",
        address: "",
        latitude: "",
        longitude: "",

        name: "",
        registerEstateAgentName: "",
        businessRegisterNumber: "",
        email: "",
        contact: "",
        fax: "",
        description: "",
        tenancyAgreementPersonInChargeEmail: "",

        sstNumber: "",
        ssmNumber: "",
        ejenHartanahNumber: "",
        branchCode: ""
      },

      validator: {
        required: required,
        email: email,
        phone: phone,
        website: website,
        fax: fax,
        minLength: minLength
      }
    };
  },
  mounted() {
    this.initData();
  },
  methods: {
    async initData() {
      this.$store.commit("setIsLoading", true);
      await Promise.all([this.getAgencies()]);
      this.$store.commit("setIsLoading", false);
    },
    // ================================= Misc ==================================
    mapArrDataIntoObjWithId(array) {
      let formData = [];
      array.forEach((val) => {
        formData.push({ id: val });
      });
      return formData;
    },
    // ============================== API Related ==============================
    async getAgencies() {
      try {
        let data = await this.$store.dispatch("manageAgency/getAllAgencies", {
          limit: 100
        });
        this.agencyOptions = data.data;
      } catch (error) {
        throw error;
      }
    },
    updateMap(val) {
      this.newBranch = {
        ...this.newBranch,
        latitude: val.geometry.lat,
        longitude: val.geometry.lng
      };
    },
    validateFail() {
      this.$notify({
        group: "alert",
        type: "error",
        title: "Invalid Input",
        text: "Please check if the inputs are valid."
      });
    },
    async submitForm() {
      this.$store.commit("setIsLoading", true);
      try {
        await this.$store.dispatch(
          "manageBranch/createBranch",
          BranchModel.postBranchDetailPayload(this.newBranch)
        );
        this.$store.commit("setIsLoading", false);

        this.$notify({
          group: "alert",
          type: "success",
          title: "Success",
          text: "Branch is created successfully."
        });
        this.$router.push({ name: "ManageBranch" });
        return;
      } catch (error) {
        this.$store.commit("setIsLoading", false);

        this.$notify({
          group: "alert",
          type: "error",
          title: "Error",
          text: "An unexpected error occured. Please try again later."
        });
        console.log(error);
        throw error;
      }
    }
  }
};
</script>

<style lang="scss">
.manage-branch-add {
  form {
    @extend %formDesign;
  }
}
</style>
